import React from "react";
import { graphql } from "gatsby";
import { Block } from "glamor/jsxstyle";

import Layout from "../components/Layout";
import { READING } from "../constants";
import SEO from "../components/SEO";

/**
 * Reading page
 *
 * Contains a list of all of my favorite resources for the user to
 * check out on their own time. This can be books or blog posts online.
 */
const Reading = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <h1 style={{ fontSize: "2.6rem", marginBottom: "1.5rem" }}>Reading</h1>
      <SEO
        title={"Reading | Conor Dewey"}
        description={`What I know and how I think are heavily influenced by the books and blog
        posts below.`}
        pathname={"reading"}
      />
      <Block
        className="pageTemplateSubheader"
        fontSize="1.3rem"
        lineHeight="2rem"
        fontWeight="500"
        paddingBottom="2rem"
      >
        What I know and how I think are heavily influenced by the books and blog
        posts below.{" "}
      </Block>
      <Block>
        <div className="aboutSubheader">Self-Improvement</div>
      </Block>
      <section
        style={{
          lineHeight: "2rem",
          fontWeight: "400"
        }}
      >
        <Block paddingBottom="1.4rem">
          <ul>
            {READING.selfImprovement.map(l => (
              <li>
                <a href={l.href} target="_blank" rel="noopener noreferrer">
                  {l.title}
                </a>{" "}
              </li>
            ))}
          </ul>
        </Block>
      </section>
      <Block>
        <div className="aboutSubheader">Career</div>
      </Block>
      <section
        style={{
          fontSize: "1.1rem",
          lineHeight: "2rem",
          fontWeight: "400"
        }}
      >
        <Block paddingBottom="1.4rem">
          <ul>
            {READING.career.map(l => (
              <li>
                <a href={l.href} target="_blank" rel="noopener noreferrer">
                  {l.title}
                </a>{" "}
              </li>
            ))}
          </ul>
        </Block>
      </section>
      <Block>
        <div className="aboutSubheader">Business</div>
      </Block>
      <section
        style={{
          fontSize: "1.1rem",
          lineHeight: "2rem",
          color: "#5F656D",
          fontWeight: "400"
        }}
      >
        <Block paddingBottom="1.4rem">
          <ul>
            {READING.business.map(l => (
              <li>
                <a href={l.href} target="_blank" rel="noopener noreferrer">
                  {l.title}
                </a>{" "}
              </li>
            ))}
          </ul>
        </Block>
      </section>
      <Block>
        <div className="aboutSubheader">Product</div>
      </Block>
      <section
        style={{
          lineHeight: "2rem",
          fontWeight: "400"
        }}
      >
        <Block paddingBottom="1.4rem">
          <ul>
            {READING.product.map(l => (
              <li>
                <a href={l.href} target="_blank" rel="noopener noreferrer">
                  {l.title}
                </a>{" "}
              </li>
            ))}
          </ul>
        </Block>
      </section>
      <Block>
        <div className="aboutSubheader">Education</div>
      </Block>
      <section
        style={{
          lineHeight: "2rem",
          fontWeight: "400"
        }}
      >
        <Block paddingBottom="1.4rem">
          <ul>
            {READING.education.map(l => (
              <li>
                <a href={l.href} target="_blank" rel="noopener noreferrer">
                  {l.title}
                </a>{" "}
              </li>
            ))}
          </ul>
        </Block>
      </section>
      <Block>
        <div className="aboutSubheader">Writing</div>
      </Block>
      <section
        style={{
          lineHeight: "2rem",
          fontWeight: "400"
        }}
      >
        <Block paddingBottom="1.4rem">
          <ul>
            {READING.writing.map(l => (
              <li>
                <a href={l.href} target="_blank" rel="noopener noreferrer">
                  {l.title}
                </a>{" "}
              </li>
            ))}
          </ul>
        </Block>
      </section>
      <Block>
        <div className="aboutSubheader">Startups</div>
      </Block>
      <section
        style={{
          lineHeight: "2rem",
          fontWeight: "400"
        }}
      >
        <Block paddingBottom="1.4rem">
          <ul>
            {READING.entrepreneurship.map(l => (
              <li>
                <a href={l.href} target="_blank" rel="noopener noreferrer">
                  {l.title}
                </a>{" "}
              </li>
            ))}
          </ul>
        </Block>
      </section>
      <Block>
        <div className="aboutSubheader">Technology</div>
      </Block>
      <section
        style={{
          lineHeight: "2rem",
          fontWeight: "400"
        }}
      >
        <Block>
          <ul>
            {READING.tech.map(l => (
              <li>
                <a href={l.href} target="_blank" rel="noopener noreferrer">
                  {l.title}
                </a>{" "}
              </li>
            ))}
          </ul>
        </Block>
      </section>
    </Layout>
  );
};

export default Reading;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
